// Note: Requires flexslider 2 buddy.
	let ajaxStart = 1;
	let ajaxEnd = 12;
	let ajaxMultiple = 12;
	let ajaxActivityType = "all";

jQuery(document).ready(function($) {

	// store the slider in a local variable
	let $window = $(window);
	let flexslider = { vars:{} };

	// tiny helper function to add breakpoints
	function getGridSize() {
		return (window.innerWidth < 768) ? 1 : 3;
	}
	
	$window.load(function() {
		if ( $('.experiences-slider .flexslider').length ) {
			// Flexslider
			$('.experiences-slider .flexslider').flexslider({
				animation: "slide",
				itemMargin: 14,
				itemWidth: 376,
				maxItems: getGridSize(),
				minItems: getGridSize(),
				nextText: '<i class="fa-light fa-angle-right"></i><span class="hide-everywhere">Next</span>',
				prevText: '<i class="fa-light fa-angle-left"></i><span class="hide-everywhere">Previous</span>',
				slideshow: false,
				start: function(slider){
					//set slider instance to flexslider variable
					flexslider = slider;
					var allSlides = $('.single-offer');
					allSlides.removeClass('active');
					allSlides.eq(slider.currentSlide).addClass('active');
				},
				after: function(slider){
					var allSlides = $('.single-offer');
					allSlides.removeClass('active');
					allSlides.eq(slider.currentSlide).addClass('active');
				},
			});
		}
	});

	// check grid size on resize event
	$window.resize(function() {
		var gridSize = getGridSize();
		flexslider.vars.minItems = gridSize;
		flexslider.vars.maxItems = gridSize;
	});




	//STUFF FOR THE AJAX GRID
	$('#activity-type-dropdown').selectric({
		responsive: true,
		inheritOriginalWidth: true,
		disableOnMobile: false,
		nativeOnMobile: false,
	});

	ajaxActivityFilter(ajaxStart, ajaxEnd, ajaxActivityType, "append");

	$("select#activity-type-dropdown").on("selectric-select", function() {
		ajaxActivityType = $(this).val();
		ajaxStart = 1;
		ajaxEnd = 12;	
		ajaxActivityFilter(ajaxStart, ajaxEnd, ajaxActivityType, "replace");
	});

	jQuery("div#experiences_load_more .btn").click(function() {
		ajaxStart = ajaxStart + ajaxMultiple;
		ajaxEnd = ajaxEnd + ajaxMultiple;
		ajaxActivityFilter(ajaxStart, ajaxEnd, ajaxActivityType, "append");
		return false;			
	});

	function ajaxActivityFilter(ajaxStart, ajaxEnd, ajaxActivityType, responseType) {
		$.get("/ajax/ajaxExperiences.php?ajaxStart=" + ajaxStart + "&ajaxEnd=" + ajaxEnd + "&ajaxActivityType=" + ajaxActivityType, function(response) {
			if (responseType == "append") {
				$("div#all-experiences").append(response);
			} else if (responseType == "replace") {
				$("div#all-experiences").html(response);				
			}
			if ($("#end_of_the_road").is(":visible")) {
				$("div#experiences_load_more").hide();
			} else {
				$("div#experiences_load_more").show();						
			}
			var AccTrigger = $('.accordion-trigger');
			AccTrigger.on('click', function(){
				var currentAccordion = $(this);
				currentAccordion.children('.accordion-content').slideToggle(300);
				currentAccordion.children('.accordion-content').toggleClass('content-hidden');
				currentAccordion.find('.accordion-toggle-icon').toggle();
			});
		});
	}

}); /* end of as page load scripts */